import React from "react";

import "./App.scss";
import GlobalStyle from "../assets/styles/global";
import { ThemeProvider } from "styled-components/macro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Navigate, Route, Routes } from "react-router-dom";
import {pauTheme, sqyTheme} from "@mbj-front-monorepo/ui";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PrivateRouteCtrl } from "../components/PrivateRouteCtrl";
import { ConseillerApp } from "./apps/conseiller/ConseillerApp";
import { PublicRouteCtrl } from "../components/PublicRouteCtrl";
import { NotFound } from "../pages/NotFound/NotFound";
import { Login } from "../pages/Login/Login";
import { AcceptJoin } from "../pages/PersonnesSuivies/InvitationPortefeuille/AcceptJoin";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={process.env["REACT_APP_DEFAULT_THEME"] === 'pauTheme' ? pauTheme : sqyTheme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <Routes>
            <Route element={<PrivateRouteCtrl from={"App"} />}>
              <Route path="/conseiller/*" element={<ConseillerApp />} />
            </Route>
            <Route element={<PublicRouteCtrl />}>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/join_portefeuille" element={<AcceptJoin />} />
              {/*<Route path="/registration" element={<Registration />} />*/}
              {/*<Route path="/forgot-password" element={<ForgotPassword />} />*/}
            </Route>
            {/*<Route path="/mentions" element={<LegalMentions />} />*/}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
